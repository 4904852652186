try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
    // require('bootstrap-select');
} catch (e) {}

function scrollToTop() {
    console.log('xx')
};

const { detect } = require('detect-browser');
const browser = detect();

// handle the case where we don't detect the browser
if (browser) {
    var browserCheck = document.getElementById('browser__check');
    if(browser.name == 'ie'){
        // browserCheck.innerHTML = '<section class="bg-blue-500"><div class="container"><div class="mx-auto text-white py-2 -mx-6">Diese Seite wurde nicht für den Internet Explorer optimiert da er seit 2015 nicht mehr weiterentwickelt wird.<br>' +
        //     '<a href="https://techcommunity.microsoft.com/t5/windows-it-pro-blog/the-perils-of-using-internet-explorer-as-your-default-browser/ba-p/331732" target="_blank" class="underline">Siehe auch hier, Microsoft warnt selbst vor der Nutzung des Internet Explorers.</a><br>' +
        //     'Wir empfehlen einen zeitgemäßen Browser!</div></section>';
        browserCheck.innerHTML = '<section class="bg-blue-500" style="background-color: #4299e1;"><div class="container"><div class="mx-auto text-white py-2 -mx-6">' +
            'The site has not been optimized for Internet Explorer as it has not been further developed since 2015. To see the Energy Globe website with all functions, please use another browser, such as Firefox, Safari, Chrome, ...' +
            '</div></section>';
    }
}

// import Vue from 'vue'
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Vue.use(BootstrapVue)
// Vue.use(IconsPlugin)

// const app = new Vue({
//     el: '#app',
// });

// window.onscroll = function() {scrollFunction()};
//
// function scrollFunction() {
//     if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
//         document.getElementById("navbar").style.padding = "30px 10px";
//         document.getElementById("logo").style.fontSize = "25px";
//     } else {
//         document.getElementById("navbar").style.padding = "80px 10px";
//         document.getElementById("logo").style.fontSize = "35px";
//     }
// }

// var toTopButton = document.getElementById("toTopButton");
//
// window.onscroll = function() {
//     scrollFunction()
// };
//
// function scrollFunction() {
//     if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
//         toTopButton.style.display = "block";
//     } else {
//         toTopButton.style.display = "none";
//     }
// }
//
// function scrollToTop() {
//     document.body.scrollTop = 0;
//     document.documentElement.scrollTop = 0;
// }
